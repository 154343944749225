import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Filter from "./Filter";
import Global from "../../../language/Global.json";
import { connect } from "react-redux";

class FilterModal extends Component {
    state = {
        displayFilterModal: false,
    };

    render() {
        const {
            language,
            i18n: { filter },
        } = this.props;

        return (
            <Modal show={this.props.filterModal} className={language === "ua" && "modal-arabic"}>
                <Modal.Header closeButton onClick={this.props.closeModal}>
                    <Modal.Title>{filter}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Filter data={this.props.data} getFilterData={this.props.getFilterData} closeModal={this.props.closeModal} />
                </Modal.Body>
            </Modal>
        );
    }
}

// export default FilterModal;

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.lang,
    i18n: language.lang === "en" ? Global.en : Global.ua,
    currentUser,
});

export default connect(mapStateToProps)(FilterModal);
