import { useState } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { apiService } from "../../services/ApiService";
import Global from "../../language/Global.json";
function ControlledCarousel({ data }) {
    const [index, setIndex] = useState(0);
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ua" ? Global.ua: Global.en;
    const {
        BookNow,
        
    } = i18n;

    //console.log(data, "::");
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    //   <Carousel.Caption>

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} className={`carousel ${language === "ua"}`} interval={null}>
            {data?.map((item) => {
                return (
                    // w-100 d-flex align-items-strech
                    <Carousel.Item>
                        <img className="d-block w-100 carousel__mainImg" src={item.background_image ? apiService.imageLink + item.background_image : "/images/Rectangle 1135.png"} alt="First slide"  />
                        <Container>
                            <Carousel.Caption>
                                <div className="carousel__item__content ">
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <div className="carousel__item__img" data-aos="fade-right" data-aos-offset="200" data-aos-delay="3" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="top-bottom">
                                                <img src={apiService.imageLink + item.image} alt="" />
                                            </div>
                                        </Col>
                                        <Col sm={12} md={8}>
                                            <div className="carousel__item__info" data-aos="fade-left" data-aos-offset="200" data-aos-delay="3" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="top-bottom">
                                                <div>
                                                    <h3 className="carousel__item__info__title ">
                                                        {item.title}
                                                        {/* COMPASS SOCIAL STUDENT CURRCICULUM */}
                                                        <br />
                                                        <span className="small-sep"></span>
                                                    </h3>
                                                    <p className="carousel__item__info__desc">
                                                        {item.description}
                                                        {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s */}
                                                    </p>
                                                </div>

                                                <a className="carousel__item__info__link" href={item.link}>
                                                  {BookNow}
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Carousel.Caption>
                        </Container>
                    </Carousel.Item>
                );
            })}

            {/* <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="holder.js/800x400?text=Second slide&bg=282c34"
                    alt="Second slide"
                />

                <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="holder.js/800x400?text=Third slide&bg=20232a"
                    alt="Third slide"
                />

                <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur.
                    </p>
                </Carousel.Caption>
            </Carousel.Item> */}
        </Carousel>
    );
}

export default ControlledCarousel;
