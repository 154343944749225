import React, { Component } from "react";
import { Container, DropdownButton, Dropdown, Modal, Accordion } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import { authService } from "../../services/AuthService";
import { setUser } from "../../redux/actions-reducers/user";
import { setLanguage } from "../../redux/actions-reducers/language";
import { connect } from "react-redux";
import { apiService } from "../../services/ApiService";
import Global from "../../language/Global.json";
import MainLoader from "./MainLoader";
import MultiSideMenu from "./MultiLevelSlider";
import { generalServices } from "../../services/GeneralServices";
class Header extends Component {
    state = {
        menuOpen: false,
        otherHeader: false,
        activeHeader: 0,
        IsArabic: false,
        languageLoader: false,
        fullscreen: true,
        show: false,
        classes: [],
        menuOpenClasses: false,
        settingData: {},
    };
    componentDidMount() {
        this.getClassesNew();
        this.getSettings();

        if (window.location.pathname === "/") {
            this.setState({
                otherHeader: true,
            });
            this.props.language === "ua" ? this.setState({ IsArabic: true }) : this.setState({ IsArabic: false });
        }
        // active header
        if (window.location.pathname.toLowerCase() === "/") {
            this.setState({
                activeHeader: 1,
            });
        }
        if (window.location.pathname.toLowerCase() === "/publicpage/about") {
            this.setState({
                activeHeader: 2,
            });
        }
        if (window.location.pathname.toLowerCase() === "/teachersarea") {
            this.setState({
                activeHeader: 3,
            });
        }
        if (window.location.pathname.toLowerCase() === "/contactus") {
            this.setState({
                activeHeader: 4,
            });
        }
        if (window.location.pathname.toLowerCase() === "/DigitalPlatforms") {
            this.setState({
                activeHeader: 5,
            });
        }
    }

    async getSettings() {
        const { success, data } = await generalServices.getSettings();
        if (!success) return;
        this.setState({
            settingData: data,
        });
    }

    getClassesNew = async () => {
        const { success, data } = await generalServices.getClassesNew();
        if (!success) return;
        this.setState({ classes: data });
    };
    get accessToken() {
        return window.localStorage.getItem("accessToken");
    }
    onLanguageChange = async () => {
        this.setState({ languageLoader: true }, async () => {
            setTimeout(async () => {
                let currentLang;
                if (this.props.language === "en") {
                    localStorage.setItem("language", "ua");
                    currentLang = "ua";
                } else {
                    localStorage.setItem("language", "en");
                    currentLang = "en";
                }
                await this.props.setLanguage(currentLang);
                window.location.reload();
            }, 500);
        });
    };

    handleShow = (breakpoint) => {
        this.setState({ fullscreen: breakpoint, show: true });
    };
    Example = () => {
        // const values = [true];
        // const { fullscreen } = this.state;
        // const { show } = this.state;

        return (
            <>
                {/* <button className="me-2" onClick={() => handleShow(true)}>
                    Full screen
                </button> */}
                {/* ))} */}
                {/* <Modal
                    show={show}
                    fullscreen={fullscreen}
                    onHide={() => this.setState({ show: false })}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>Modal body content</Modal.Body>
                </Modal> */}
            </>
        );
    };

    render() {
        const { menuOpen, activeHeader, languageLoader, classes, menuOpenClasses, settingData } = this.state;
        const {
            i18n: { Home, About, ContactUs, TeachersArea, Welcome, Profile, MyOrders, MyLocations, LogOut, logIn, SignUp, lang, LogIn, EN, OurTeam, Cart, favourite, ClassesTitle, OurCatalogue, digitalPlatformsName,YourCart, MyFavorites },
            language,
        } = this.props;
        console.log(classes);

        return (
            <div
                className={`header ${language === "ua" && "header-arabic"}`}
                // style={otherHeader ? { position: "absolute" } : null}
            >
                <Container>
                    <div className="header-contents flex">
                        <a href="/">
                            <img className="logo-image" src={language === "ar" ? "/images/Artboard 1 copy 3@2x.png" : "/images/Artboard 1@2x.png"} alt="k" />
                        </a>
                        <ul className="flex header-list">
                            <li className={activeHeader === 1 ? "activeHeader" : ""}>
                                <a href="/">{Home}</a>
                            </li>
                            <li className={activeHeader === 2 ? "activeHeader" : ""}>
                                <a href="/PublicPage/about">{About}</a>
                            </li>
                            {/* <li
                                className={
                                    activeHeader === 3 ? "activeHeader" : ""
                                }
                            >
                                <a href="/">{TeachersArea}</a>
                            </li> */}
                            <li className={activeHeader === 3 ? "activeHeader" : ""}>
                                <button
                                    className="classesButton"
                                    // onClick={() => this.handleSidebarToggle()}
                                >
                                    {/* <a href="/CategoryPage">{ClassesTitle}</a> */}
                                </button>
                                <div className="category-push-menu">
                                    {/* <MultiSideMenu
                                        menuOpen={menuOpenClasses}
                                        options={classes.map((_class) => {
                                            return {
                                                content: [
                                                    {
                                                        id: _class.id,
                                                        name: `${_class.name}`,
                                                        // subjects start
                                                        children: _class.subjects?.map((sub) => {
                                                            return {
                                                                content: [
                                                                    {
                                                                        name: sub.name,
                                                                        // children: sub.series.map((se) => {
                                                                        //     return {
                                                                        //         content: [
                                                                        //             {
                                                                        //                 name: se.name,
                                                                        //                 to: `/CategoryPage/${_class.id}/${sub.id}/${se.id}`,
                                                                        //             },
                                                                        //         ],
                                                                        //     };
                                                                        // }),
                                                                    },
                                                                ],
                                                            };
                                                        }),
                                                    },
                                                ],
                                            };
                                        })}
                                        handleSidebarToggle={this.handleSidebarToggle}
                                    /> */}
                                    {/* using in our button to open the sidebar */}
                                </div>
                            </li>
                            <li className={activeHeader === 4 ? "activeHeader" : ""}>
                                <a href="/ContactUs">{ContactUs}</a>
                            </li>
                            <li className={activeHeader === 5 ? "activeHeader" : ""}>
                                <a href="/DigitalPlatforms">{digitalPlatformsName}</a>
                            </li>

                            {/* <li className={activeHeader === 5 ? "activeHeader" : ""}>
                                <a href={settingData?.flip_book} target="_blank">
                                    {OurCatalogue}
                                </a>
                            </li> */}
                        </ul>
                        {/* aaaa */}
                        <div className="cart-fav flex">
                            <a href={this.accessToken ? "/Cart" : "/login"} className="single-cart">
                                <svg id="fi-rr-shopping-cart" className="PrimaryColor" xmlns="http://www.w3.org/2000/svg" width="20.712" height="31.846" viewBox="0 0 30.712 31.846">
                                    <title>{YourCart}</title>
                                    <path  id="Path_1" data-name="Path 1" class="cls-1" d="M29.8,5.384a3.916,3.916,0,0,0-3.021-1.422H5.565L5.51,3.5A3.945,3.945,0,0,0,1.6,0H1.312a1.321,1.321,0,0,0,0,2.641H1.6a1.315,1.315,0,0,1,1.3,1.166l1.805,15.45a6.575,6.575,0,0,0,6.515,5.833h13.7a1.321,1.321,0,0,0,0-2.641h-13.7a3.937,3.937,0,0,1-3.7-2.641H23.164a6.571,6.571,0,0,0,6.456-5.432l1.03-5.749A3.972,3.972,0,0,0,29.8,5.384ZM28.074,8.158l-1.031,5.749a3.943,3.943,0,0,1-3.879,3.259H7.109L5.875,6.6h20.9a1.308,1.308,0,0,1,1.012.471A1.327,1.327,0,0,1,28.074,8.158Z" />
                                    <ellipse  id="Ellipse_1" data-name="Ellipse 1" class="cls-1" cx="2.484" cy="2.5" rx="2.484" ry="2.5" transform="translate(6.707 26.846)" />
                                    <ellipse  id="Ellipse_2" data-name="Ellipse 2" class="cls-1" cx="2.484" cy="2.5" rx="2.484" ry="2.5" transform="translate(19.623 26.846)" />
                                </svg>
                                {this.props.currentUser.cart_count > 0 && <span>{this.props.currentUser.cart_count}</span>}
                            </a>
                            <a  href={this.accessToken ? "/Favorites" : "/login"} className="single-cart"> 
                                <svg className="PrimaryColor" xmlns="http://www.w3.org/2000/svg" width="20.487" height="26.787" viewBox="0 0 30.487 26.787">
                                <title>{MyFavorites}</title>
                                    <path id="fi-rr-heart" class="cls-1" d="M22.223,1.917a8.127,8.127,0,0,0-6.984,4.19,8.127,8.127,0,0,0-6.984-4.19A8.635,8.635,0,0,0,0,10.869c0,5.774,6.077,12.079,11.174,16.355a6.316,6.316,0,0,0,8.127,0c5.1-4.275,11.174-10.581,11.174-16.355a8.635,8.635,0,0,0-8.254-8.952ZM17.67,25.281a3.775,3.775,0,0,1-4.863,0C6.283,19.807,2.541,14.555,2.541,10.869A6.1,6.1,0,0,1,8.255,4.457c3.343.2,5.895,2.068,5.714,5.412a1.27,1.27,0,1,0,2.54,0c-.181-3.344,2.371-5.208,5.714-5.412a6.1,6.1,0,0,1,5.714,6.412c0,3.686-3.742,8.938-10.266,14.407Z" transform="translate(0.005 -1.917)"/>
                                </svg> 
                                {this.props.currentUser.fav_count > 0 && <span>{this.props.currentUser.fav_count}</span>}
                            </a>
                            <span
                                className="single-cart language-switch"
                                onClick={() => {
                                    this.onLanguageChange();
                                }}
                            >
                                {EN}
                            </span>
                        </div>
                        {languageLoader && (
                            <Modal show={true} fullscreen={true} onHide={() => this.setState({ show: false })} className="languageLoaderModal">
                                <Modal.Body>
                                    <MainLoader />{" "}
                                </Modal.Body>
                            </Modal>
                        )}
                        <div className="user-auth-Buttons">
                            {this.accessToken ? (
                                <div className="user-dropdown flex">
                                    <img src={this.props.currentUser.image ? apiService.imageLinkProfile + this.props.currentUser.image : "/images/Group 510@3x.png"} alt="l" />

                                    <DropdownButton
                                        id="dropdown-basic-button"
                                        title={
                                            <div>
                                                <span>{Welcome}</span>
                                                <p>{this.props.currentUser.name}</p>
                                            </div>
                                        }
                                    >
                                        <Dropdown.Item href="/profile">{Profile}</Dropdown.Item>
                                        <Dropdown.Item href="/MyOrders">{MyOrders}</Dropdown.Item>

                                        <Dropdown.Item href="/MyLocations">{MyLocations}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => authService.logout()}>{LogOut}</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            ) : (
                                <div className="auth-Buttons">
                                    <a href="/login">{logIn}</a> / <a href="/signUp">{SignUp}</a>
                                </div>
                            )}
                        </div>
                        {/* push menu start  */}
                        <div className="mobile-push-menu">
                            <Menu right={language === "ua" ? true : false} isOpen={menuOpen} customBurgerIcon={<i className="fa fa-bars" />} customCrossIcon={<i className="fa fa-times croosIconMenu" />}>
                                <div className="sideMenu_logo">
                                    <a href="/" onClick={() => this.closeMenu()}>
                                        <img className="logo-image" src={language === "ua" ? "/images/Artboard 1 copy 3@2x.png" : "/images/Artboard 1@2x.png"} alt="logo" />
                                    </a>
                                </div>

                                <div className="sideMenu_List">
                                    <ul className="mb-0 mt-3">
                                        {this.accessToken ? (
                                            <Accordion flush defaultActiveKey="1">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <div className="sideMenu_List__img flex">
                                                            <img src={this.props.currentUser.image ? apiService.imageLink + this.props.currentUser.image : "/images/Group 510@3x.png"} alt="l" />
                                                            <span>{this.props.currentUser.name}</span>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <a href="/profile">{Profile}</a>
                                                        <a href="/MyOrders">{MyOrders}</a>

                                                        <a href="/MyLocations">{MyLocations}</a>

                                                        <a href={"/Cart"} className="cart-and-favourite-mobile">
                                                            <p>{Cart}</p>
                                                            {this.props.currentUser.cart_count > 0 && <p className="cart-and-favourite-mobile__qty">{this.props.currentUser.cart_count}</p>}
                                                        </a>
                                                        <a href={"/Favorites"} className="cart-and-favourite-mobile">
                                                            <p>{favourite}</p>
                                                            {this.props.currentUser.fav_count > 0 && <p className="cart-and-favourite-mobile__qty">{this.props.currentUser.fav_count}</p>}
                                                        </a>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        ) : null}

                                        <li>
                                            <a href="/" onClick={() => this.closeMenu()}>
                                                {Home}
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/PublicPage/about" onClick={() => this.closeMenu()}>
                                                {About}
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a
                                                href="/"
                                                onClick={() => this.closeMenu()}
                                            >
                                                {TeachersArea}
                                            </a>
                                        </li> */}
                                        <li className={activeHeader === 3 ? "activeHeader" : ""}>
                                            <a href="/CategoryPage/1/1">{ClassesTitle}</a>
                                        </li>
                                        <li className={activeHeader === 4 ? "activeHeader" : ""}>
                                            <a href="/ContactUs">{ContactUs}</a>
                                        </li>
                                        <li className={activeHeader === 5 ? "activeHeader" : ""}>
                                            <a href="/DigitalPlatforms">{digitalPlatformsName}</a>
                                        </li>

                                        <li className={activeHeader === 5 ? "activeHeader" : ""}>
                                            <a href={"/" + settingData?.flip_book} target="_blank">
                                                {OurCatalogue}
                                            </a>
                                        </li>

                                        <li>
                                            <a href="#" onClick={() => this.onLanguageChange()}>
                                                {lang}
                                            </a>
                                        </li>
                                        {this.accessToken ? (
                                            <li>
                                                <a href="/" onClick={() => authService.logout()}>
                                                    {LogOut}
                                                </a>
                                            </li>
                                        ) : (
                                            <li>
                                                <a href="/Login" onClick={() => this.closeMenu()}>
                                                    {LogIn}
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </Menu>
                        </div>
                        {/* push menu start  */}
                    </div>
                </Container>
            </div>
        );
    }

    closeMenu() {
        this.setState({ menuOpen: false });
    }
    toggleMenu() {
        this.setState((state) => ({ menuOpen: !state.menuOpen }));
    }
    handleSidebarToggle = () => {
        this.setState((state) => ({ menuOpenClasses: !state.menuOpenClasses }));
    };
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ua" ? Global.ua : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setUser, setLanguage })(Header);
