import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import Slider from "react-slick";
import Product from "../../blocks/Product";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json";
import { connect } from "react-redux";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SquareProduct from "../../blocks/SquareProduct";
// import Carousel from "react-elastic-carousel";
function NewTitle({ title, data, reFetchData, type }) {
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ua" ? Global.ua : Global.en;
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        laptop: {
            breakpoint: { max: 1399, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    return (
        <div
            className={`new-title ${type == "bigsale" && "bg-gray-container"}`}
        >
            {data?.length > 0 && (
                <Container>
                    <div className="section-title">
                        <h3>{title}</h3>
                    </div>
                    {/* <Row> */}

                    {/* <Carousel
                        pagination={false}
                        enableAutoPlay
                        itemPadding={[10, 5]}
                        isRTL={language === "ar" ? true : false}
                        breakPoints={[
                            { width: 1, itemsToShow: 1 },
                            {
                                width: 550,
                                itemsToShow: 2,
                                itemsToScroll: 2,
                                pagination: false,
                            },
                            { width: 850, itemsToShow: 3 },
                            { width: 1150, itemsToShow: 3, itemsToScroll: 1 },
                            { width: 1450, itemsToShow: 5 },
                            { width: 1750, itemsToShow: 6 },
                        ]}
                    > */}
                    <Carousel
                        className={
                            data.length < 3 && window.innerWidth > 576
                                ? "rtl"
                                : "ltr"
                        }
                        responsive={responsive}
                        dotListClass="custom-dot-list-style"
                        arrows={true}
                        // infinite={data.length < 3 ? false : true}
                        itemClass="carousel-item-padding-40-px"
                        autoPlay
                        autoPlaySpeed={3000}
                    >
                        {data?.map((item, index) => {
                            if (type == "bigsale") {
                                return (
                                    <Product
                                        key={index}
                                        className="new-titles-product text-center"
                                        item={item}
                                        reFetchData={reFetchData}
                                    />
                                );
                            }
                            return (
                                <SquareProduct
                                    key={index}
                                    className="new-titles-product text-center"
                                    item={item}
                                    reFetchData={reFetchData}
                                />
                            );
                        })}
                    </Carousel>
                    {/* </Row> */}
                </Container>
            )}
        </div>
    );
}

export default NewTitle;
