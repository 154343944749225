import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { generalServices } from "../../../services/GeneralServices";
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import TextInput from "../../blocks/TextInput";
import AgentOf from "../home/AgentOf";
import Global from "../../../language/Global.json";
import { connect } from "react-redux";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { apiService } from "../../../services/ApiService";

class ContactUs extends Component {
    state = {
        footerData: {},
        SocialData: [],
        fields: { name: "", email: "", message: "" },
        errors: { name: "", email: "", message: "" },
        StaticImageData: {},
        settingData: {},
    };

    componentDidMount() {
        this.getFooterData();
        this.getSocialLinks();
        this.getStaticImage();
        this.getSettings();
    }

    async getSettings() {
        const { success, data } = await generalServices.getSettings();
        if (!success) return;
        this.setState({
            settingData: data,
        });
    }

    getStaticImage = async () => {
        const { success, data } = await generalServices.getStaticImage(2);

        if (!success) return;
        this.setState({
            StaticImageData: data,
        });
    };
    async getFooterData() {
        const { success, data } = await generalServices.getFooterData();

        if (!success) return;
        this.setState({
            footerData: data,
            pageLoader: false,
        });
    }
    async getSocialLinks() {
        const { success, data } = await generalServices.getSocialLinks();

        if (!success) return;
        this.setState({
            SocialData: data,
            pageLoader: false,
        });
    }

    render() {
        const {
            fields,
            errors,
            footerData,
            SocialData,
            StaticImageData,
            settingData,
        } = this.state;
        const onFieldChange = (name, value) =>
            this.setState({
                fields: { ...fields, [name]: value },
                errors: { name: "", email: "", message: "" },
            });
        const {
            i18n: {
                ContactUs,
                Name,
                Email,
                Message,
                Send,
                OrBY,
                Phone,
                Address,
            },
            language,
        } = this.props;

        return (
            <div
                className={`contact-us ${
                    language === "ua" 
                }`}
            >
                <div className="page-label"/>
                <Container>
                    <h1 className="page-title" style={{paddingTop: '6px'}}>{ContactUs}</h1>

                    <div
                        className="form-box"
                        data-aos="fade-up"
                        data-aos-offset="200"
                        data-aos-delay="3"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true"
                        data-aos-anchor-placement="top-bottom"
                    >
                        <Row>
                            <Col md={6}>
                                {/* <img scc="/images/" alt="l" /> */}
                                {/* <img
                                    src={
                                        apiService.imageLink +
                                        StaticImageData?.image
                                    }
                                    alt="l"
                                    className="main-form-img contactUs-main-form-img"
                                /> */}
                                <div className="contact-details">
                                    <h4>{OrBY}</h4>
                                    <div>
                                        <ul className="addresses-list">
                                            <li>
                                                <p>{Email}: </p>info@omega-zone.com
                                                <span>
                                                    <a
                                                        href={
                                                            "mailto:" +
                                                            settingData?.email
                                                        }
                                                    >
                                                        {settingData?.email}
                                                    </a>
                                                </span>
                                            </li>

                                            <li>
                                                {Phone}:<p> +38 0 635 764 450/ +479 44 59 31 1</p>
                                                <span>
                                                    <a
                                                        href={
                                                            "tel:" +
                                                            settingData?.phone
                                                        }
                                                    >
                                                        {settingData?.phone}
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                {Address}:<p> LC "OMEGA ZONE", 03067 Kyiv, Street Harmatna, 18, office 5
</p>
                                                <span>
                                                    {settingData?.address}
                                                </span>
                                            </li>
                                            <li>
                                                
                                                <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3385.0743545118094!2d35.92425631516141!3d31.95887948122669!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7f8bff5b02aa0b29!2zMzHCsDU3JzMyLjAiTiAzNcKwNTUnMzUuMiJF!5e0!3m2!1sen!2sjo!4v1642582881989!5m2!1sen!2sjo"
                                                    width="100%"
                                                    height="204"
                                                ></iframe>
                                            </li>
                                        </ul>

                                        {/* <ul className="social-media">
                                            {SocialData.map(
                                                ({ icon, link, id }) => {
                                                    return (
                                                        <li key={id}>
                                                            <a href={`${link}`}>
                                                                <i
                                                                    className={`fab fa-${icon}`}
                                                                ></i>
                                                            </a>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul> */}
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className="form-col">
                                <TextInput
                                    name="name"
                                    label=""
                                    placeholder={Name}
                                    value={fields.name}
                                    onFieldChange={onFieldChange}
                                    validate={errors.name}
                                />

                                <TextInput
                                    name="email"
                                    label=""
                                    placeholder={Email}
                                    value={fields.email}
                                    onFieldChange={onFieldChange}
                                    validate={errors.email}
                                />

                                <TextInput
                                    name="message"
                                    label=""
                                    placeholder={Message}
                                    value={fields.message}
                                    onFieldChange={onFieldChange}
                                    validate={errors.message}
                                    isTextArea
                                />

                                <button
                                    className="submit-button"
                                    onClick={() => {
                                        this.submit();
                                    }}
                                >
                                    {Send}
                                </button>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <AgentOf />
            </div>
        );
    }

    submit = async () => {
        const { fields } = this.state;
        const { Done, MessageSent } = this.props.i18n;
        const { success, data, message, errors } =
            await generalServices.sendContactMessage(fields);

        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert("Error", message, "error");
            }
        }

        displayAlert(Done, MessageSent, "success").then(() =>
            this.setState({ fields: { name: "", email: "", message: "" } })
        );
    };
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ua" ? Global.ua : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setUser, setLanguage })(ContactUs);
