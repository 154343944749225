import React, { Component } from "react";
import { Col, Container, Row, Modal, InputGroup } from "react-bootstrap";
import { apiService } from "../../../services/ApiService";
import { authService } from "../../../services/AuthService";
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import TextInput from "../../blocks/TextInput";
import AgentOf from "../home/AgentOf";
import { connect } from "react-redux";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import Global from "../../../language/Global.json";
import { profileService } from "../../../services/Profile";
import { ErrorMessage } from "../../blocks/ErrorMessage";
class Login extends Component {
    state = {
        AddAddress: false,
        fields: {
            email: "",
            password: "",
            // checkEmail: "",
        },
        errors: {
            email: "",
            password: "",
            // checkEmail: "",
        },
        openEyes: true,
    };
    closeModal = () => {
        this.setState({
            AddAddress: false,
        });
    };

    render() {
        const onFieldChange = (name, value) =>
            this.setState({
                fields: { ...fields, [name]: value },
                errors: {
                    email: "",
                    password: "",
                    // checkEmail: "",
                },
            });

        const { fields, AddAddress, errors, openEyes } = this.state;
        const {
            i18n: {
                logIn,
                Password,
                ForgetMyPassword,
                SignUp,
                ResetYourPassword,
                Email,
                ResetPassword,
                DontHaveAccount,
            },
            language,
        } = this.props;
        return (
            <div
                className={`contact-us auth ${
                    language === "ua" && "contact-us-arabic auth-arabic"
                }`}
            >
                <div className="page-label" />
                <Container>
                    <h3 className="page-title">{logIn}</h3>
                    <div className="form-box" data-aos="fade-up" data-aos-offset="200" data-aos-delay="3" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="top-bottom">
                        <Row>
                            <Col md={6}>
                                <TextInput
                                    name="email"
                                    label=""
                                    placeholder={Email}
                                    value={fields.email}
                                    onFieldChange={onFieldChange}
                                    validate={errors.email}
                                />
                                <InputGroup className="mb-3 flex input-group1">
                                    <input
                                        className="password-input"
                                        type={openEyes ? "password" : "text"}
                                        label=""
                                        name="password"
                                        placeholder={Password}
                                        value={fields.password}
                                        // validate={errors.password}
                                        onChange={(e) => {
                                            this.setState({
                                                fields: {
                                                    ...this.state.fields,
                                                    password: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                    <i
                                        onClick={() =>
                                            this.setState({
                                                openEyes: !openEyes,
                                            })
                                        }
                                        className={
                                            openEyes
                                                ? "fa fa-eye-slash fa-1x"
                                                : "fa fa-eye fa-1x"
                                        }
                                    ></i>
                                </InputGroup>
                                <div className="forget-password-and-error-message">
                                    <ErrorMessage message={errors.password} />

                                    <span
                                        className="forget-password"
                                        onClick={() =>
                                            this.setState({ AddAddress: true })
                                        }
                                    >
                                        {ForgetMyPassword}
                                    </span>
                                </div>
                                <button
                                    className="submit-button"
                                    onClick={() => {
                                        //   this.addUpdateUserLocation();
                                        this.submit();
                                    }}
                                >
                                    {logIn}
                                </button>
                                <p>
                                    {DontHaveAccount}{" "}
                                    <a href="/signup">{SignUp}</a>
                                </p>
                            </Col>

                            <Col md={6}>
                                {/* <img scc="/images/" alt="l" /> */}
                                <img
                                    src="/images/Group 50411.png"
                                    alt="l"
                                    className="main-form-img"
                                />
                            </Col>
                        </Row>
                    </div>

                    {/* add/edit address modal */}
                    <Modal
                        show={AddAddress}
                        width="600"
                        effect="fadeInUp"
                        onHide={this.closeModal}
                        className="location-modal"
                    >
                        <div className="modal-title flex">
                            <h5>{ResetYourPassword}</h5>
                            <i
                                onClick={this.closeModal}
                                className="fa fa-times"
                            ></i>
                        </div>
                        <div className="address-inputs">
                            <Row>
                                {/* <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    style={{ marginBottom: "1rem" }}
                                >
                                    <TextInput
                                        name="checkEmail"
                                        label={Email}
                                        placeholder={Email}
                                        value={fields.checkEmail}
                                        onFieldChange={onFieldChange}
                                        validate={errors.checkEmail}
                                    />
                                </Col> */}

                                <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    className="locationButton"
                                >
                                    <button
                                        className="submit-button"
                                        onClick={() => {
                                            //   this.addUpdateUserLocation();
                                            this.checkEmail();
                                        }}
                                    >
                                        {ResetPassword}
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </Modal>
                    {/* end add/edit address modal */}
                </Container>
                <AgentOf />
            </div>
        );
    }

    submit = async () => {
        const { fields } = this.state;
        const { success, data, message, errors } = await authService.login({
            email: fields.email,
            password: fields.password,
        });

        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert("Error", message, "error");
            }
        }

        apiService.storeToken(data.access_token);

        this.props.setUser({ ...data.user });

        window.location.href = "/";
    };

    checkEmail = async () => {
        const { fields } = this.state;
        const { success, message, errors } = await profileService.checkEmail({
            email: fields.checkEmail,
        });
        const { Done, CheckYourEmail } = this.props.i18n;
        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: {
                        ...this.state.errors,
                        checkEmail: handelErrors.email,
                    },
                });
                return;
            } else {
                return displayAlert("Error", message, "error");
            }
        }
        displayAlert(Done, CheckYourEmail, "success").then(() =>
            this.closeModal()
        );
    };
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ua" ? Global.ua : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setUser, setLanguage })(Login);
