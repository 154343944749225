import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { apiService } from "../../../services/ApiService";
import { generalServices } from "../../../services/GeneralServices";
import Global from "../../../language/Global.json";
import Carousel from "react-multi-carousel";

function AgentOf() {
    const [partners, setPartners] = useState([]);

    const sortAgentsData = (_data) => {
        const sortedData = new Array();

        for (let i = 0; i < _data.length; i++) {
            _data.sort(_data[i]);
        }
    };

    const getPartners = async () => {
        const { data } = await generalServices.getPartners();

        // sortAgentsData(data);

        setPartners(
            data?.sort((a, b) => {
                return a.sort - b.sort;
            })
        );
    };
    useEffect(() => {
        getPartners();
    }, []);
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ua" ? Global.ua : Global.en;
    const { AgentOf } = i18n;

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
        },
    };
    return (
        <div className="agent-of">
            <Container>
                {partners?.length > 0 && (
                    <>
                        <h3 className="text-center">{AgentOf}</h3>
                        <Row>
                            <Carousel
                                slidesToSlide={true}
                                infinite={true}
                                responsive={responsive}
                                dotListClass="custom-dot-list-style"
                                arrows={true}
                                // infinite={data.length < 3 ? false : true}
                                itemClass="carousel-item-padding-40-px"
                                autoPlay
                                autoPlaySpeed={3000}
                            >
                                {partners?.map(({ logo, id, url }) => {
                                    return (
                                        <a href={url} target="_blank" rel="noreferrer">
                                            <div className="agent-of__item">
                                                <img src={apiService.imageLink + logo} alt="l" />
                                            </div>
                                        </a>
                                    );
                                })}
                            </Carousel>
                        </Row>
                    </>
                )}
            </Container>
        </div>
    );
}

export default AgentOf;
