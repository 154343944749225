import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import HorizontalProduct from "../../cart/HorizontalProduct";
import AgentOf from "../../home/AgentOf";
import { connect } from "react-redux";
import { setLanguage } from "../../../../redux/actions-reducers/language";
import { setUser } from "../../../../redux/actions-reducers/user";
import Global from "../../../../language/Global.json";
class OrderDetails extends Component {
    state = {
        step: 1,
        qty: 1,
        selectedLocation: 0,
        selectedPayment: 0,
        orderData: this.props.location?.state?.orderData ?? [],
    };
    render() {
        const { orderData } = this.state;
        const {
            i18n: {
                orderDetails,
                items,
                Invoice,
                Books,
                Price,
                TAX1,
                DELEVERYFEE,
                Total,
                DeliveredTo,
                PaymentBy,
                CashFee,
            },
        } = this.props;
        return (
            <div className="contact-us cart">
                <div className="page-label" />
                <Container>
                    <div className=" cart-header">
                        <Row>
                            <Col md={5}>
                                <h3 className="page-title">{orderDetails}</h3>{" "}
                            </Col>
                        </Row>
                    </div>

                    <div className="form-box">
                        <Row>
                            <Col md={6}>
                                <h4>{items}</h4>
                                {orderData?.order_details?.map((data) => {
                                    return (
                                        <HorizontalProduct
                                            fromOrders
                                            data={data}
                                        />
                                    );
                                })}
                            </Col>
                            <Col md={6}>
                                <div className="invoice">
                                    <h4>{Invoice}</h4>
                                    {/* invoice list start  */}
                                    <ul>
                                        <li className="title-list">
                                            <span>{Books}</span>
                                            <span>{Price}</span>
                                        </li>
                                        {orderData?.order_details?.map(
                                            ({
                                                quantity,
                                                total_price,
                                                unit_price,
                                                book,
                                            }) => {
                                                return (
                                                    <li>
                                                        <span>
                                                            {book?.title}
                                                        </span>
                                                        <span>
                                                            {(book?.campaign
                                                                ? this.newPrice(
                                                                      book?.campaign,
                                                                      book?.price
                                                                  )
                                                                : book?.price) *
                                                                quantity}
                                                            $
                                                        </span>
                                                    </li>
                                                );
                                            }
                                        )}
                                        <li>
                                            <span>{DELEVERYFEE}</span>
                                            <span>
                                                {parseInt(
                                                    orderData?.delivery_amount ??
                                                        0,
                                                    10
                                                )}
                                                $
                                                {/* {parseInt(
                                                            selectedLocation
                                                                ?.country_info
                                                                ?.delivery_cost ??
                                                                0,
                                                            10
                                                        )} */}
                                            </span>
                                        </li>

                                        <li className="tax">
                                            <span>{TAX1}</span>
                                            <span>
                                                {orderData?.tax_amount}$
                                            </span>
                                        </li>
                                        <li>
                                            <span>{CashFee}</span>
                                            <span>3$</span>
                                        </li>
                                        <li className="total">
                                            <span>{Total}</span>
                                            <span>
                                                {" "}
                                                {orderData?.total_amount}
                                                {/* {orderData?.total_amount *
                                                    orderData?.tax_amount +
                                                    (orderData?.delivery_amount +
                                                        orderData?.total_amount)} */}
                                                $
                                            </span>
                                        </li>
                                        {/* invoice location start  */}

                                        <div className="invoice-location-insert">
                                            <h5>{DeliveredTo}</h5>

                                            <div className="location__header flex">
                                                <h6>
                                                    {
                                                        orderData?.user_location
                                                            ?.name
                                                    }
                                                </h6>
                                            </div>
                                            <p>
                                                {
                                                    orderData?.user_location
                                                        ?.address
                                                }
                                            </p>
                                            <div className="location-footer flex">
                                                <span>
                                                    {
                                                        orderData?.user_location
                                                            ?.phone
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        {/* invoice location end  */}

                                        {/* invoice Payment start  */}

                                        <div className="invoice-location-insert">
                                            <h5>{PaymentBy}</h5>

                                            <div className="location__header flex">
                                                <h6>
                                                    {orderData?.payment_method}{" "}
                                                </h6>
                                            </div>
                                        </div>
                                        {/* invoice Payment end  */}
                                    </ul>
                                    {/* invoice list end  */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>

                <AgentOf />
            </div>
        );
    }
    newPrice = (campaign, price) => {
        let discountAmount = (price * campaign.percentage) / 100;

        let priceAfterDiscount = 0;
        if (discountAmount > campaign.fixed_amount) {
            priceAfterDiscount = price - campaign.fixed_amount;

            return priceAfterDiscount;
        } else {
            priceAfterDiscount = price - discountAmount;
            return priceAfterDiscount;
        }
    };
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ua" ? Global.ua : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setUser, setLanguage })(OrderDetails);
