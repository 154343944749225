import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { apiService } from "../../services/ApiService";
import Global from "../../language/Global.json";

function SeriesBlock({ series }) {
    const prams = useParams();
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ua" ? Global.ua : Global.en;
    const { readmore } = i18n;

    return (
        <div className="series-block shadow mt-5" data-aos="fade-up" data-aos-offset="200" data-aos-delay="3" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="top-bottom">
            <Row>
                <Col sm={12} md={3}>
                    <div className="series-block__img-container text-center">
                        <img src={apiService.imageLink + series.image} alt="series" />
                    </div>
                </Col>
                <Col md={9} sm={12}>
                    <div className="series-block__info__container d-flex flex-column justify-content-between">
                        <div className="series-block__info mt-3">
                            <p className="series-block__info__title">{series?.name}</p>
                            <p className="series-block__info__desc">{series.description}</p>
                        </div>
                        <div className="series-block__btn w-100 text-end">
                            <a href={`/CategoryPage2/${prams.subject_id}/${series.id}/${prams.stage_id}`}>{readmore}</a>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default SeriesBlock;
