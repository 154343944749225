import React, { useEffect, useState } from "react";
import { Carousel, Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { generalServices } from "../../../services/GeneralServices";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json";
import { apiService } from "../../../services/ApiService";
import MultiSideMenu from "../../blocks/MultiLevelSlider";
import ControlledCarousel from "../../blocks/ControlledCarousel";
function LandingPage({ data }, props) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [, setPageLoader] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [classes, setClasses] = useState([]);
    const [options_, setOptions_] = useState([]);
    const [sliders, setSliders] = useState([]);
    const [Loader, setLoader] = useState(true);
    const [isArabic] = useState(false);
    const closeMenu = () => {
        setMenuOpen(false);
    };

    const [key, setKey] = useState("");
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ua" ? Global.ua : Global.en;
    const { BuyNow, Categories, Search1, Classes1, Subjects1, ClassesTitle } = i18n;
    useEffect(async () => {
        await Promise.all([getSliders()]);
    }, []);

    const handleSidebarToggle = () => {
        setMenuOpen(!menuOpen);
    };
    const getSliders = async () => {
        const { data, success } = await generalServices.getSliders();
        if (!success) return;
        setSliders(data);
        setLoader(false);
    };

    console.log(sliders);
    return (
        <div className={`landing-page ${sliders.length <= 0 && "landing-page-No-Carousel-Data"}  ${language === "ua"}`}>
            {/* {sliders.length > 0 && } */}
            <ControlledCarousel data={sliders} />
            {/* landing contents end  */}

            {/* <Carousel fade>
                {sliders.map((item, index) => {
                    return (
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                // src="/images/Group 5066.png"
                                src={apiService.imageLink + item.image}
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h3 className="carousel-caption__title">
                                    {item.title}
                                </h3>
                                <p className="carousel-caption__description">
                                    {item.description}
                                </p>
                                <hr />
                                <button
                                    className="carousel-caption__button"
                                    onClick={() =>
                                        // window.location.href ="/productDetails/" + data.id
                                        window.open(`${item.link}`, "_blank")
                                    }
                                >
                                    {BuyNow}
                                </button>
                            </Carousel.Caption>
                        </Carousel.Item>
                    );
                })}
            </Carousel> */}
            {!Loader && (
                <Container className="search-container">
                    {/* search block start  */}
                    <div className="search-block flex shadow">
                        {/* <span><a href="/CategoryPage/1/1/1" style={{color: "#707070"}}>{ClassesTitle}</a></span> */}
                        <InputGroup size="sm" className="">
                            <InputGroup.Text id="inputGroup-sizing-sm">{/* <img src="/images/fi-rr-search.svg" alt="l" /> */}</InputGroup.Text>
                            <FormControl
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                onChange={(key) => {
                                    setKey(key.target.value);
                                }}
                            />
                            <InputGroup.Text
                                id="inputGroup-sizing-sm"
                                onClick={() => {
                                    if (key === "") {
                                    } else {
                                        Search(key);
                                    }
                                }}
                                style={{ backgroundColor: "$primary-color", border: "none" }}
                            >
                                {Search1}
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                    {/* search block end  */}
                </Container>
            )}
        </div>
    );
}
const Search = (key) => {
    if (key.includes("%")) {
        window.location.href = "/Search/" + key.replace("%", "%25");
    } else {
        window.location.href = "/Search/" + key;
    }
};
export default LandingPage;
